import type { RouterOptions } from '@nuxt/schema';

export default <RouterOptions>{
  scrollBehavior(to, from, savedPosition) {
    const fromSpotDetailToSpotList = from.name == 'spots-id' && (to.name === 'spots' || to.name === 'index' || to.name === 'users-nameOrOldId' || to.name === 'profile' || to.name === 'premium');
    const fromSpotListToSpotDetail = (from.name === 'spots' || from.name === 'index' || from.name === 'users-nameOrOldId' || from.name === 'profile' || from.name === 'premium') && to.name == 'spots-id';

    const fromNewsDetailToNewsList = from.name == 'news-slug' && (to.name === 'index' || to.name === 'news');
    const fromNewsListToNewsDetail = (from.name === 'index' || from.name === 'news') && to.name == 'news-slug';

    if (fromSpotDetailToSpotList || fromNewsDetailToNewsList) {
      if (savedPosition) {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(savedPosition);
          }, 250);
        });
      }
    } else if (fromSpotListToSpotDetail || fromNewsListToNewsDetail) {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve({ top: 0 });
          }, 300);
        });
    }

    if (!(to.name === 'spots-id' && to.hash)) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ top: 0 });
        }, 300);
      });
    }


  }
};

